<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 ps-0">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">非凸智能算法正式上线中泰XTP系统</p>
              <div class="fst-italic mb-2">2020年09月14日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中泰证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <figure class="mb-4">
                  <img
                    class="img-fluid rounded w-100"
                    src="../../assets/img/news/02/image01.jpg"
                    alt="非凸智能算法正式上线中泰XTP系统"
                  />
                </figure>
                <section class="mb-5">
                  <p class="mb-4">
                    非凸智能算法于2020年9月14日作为首家智能算法服务商正式上线中泰XTP系统。此次上线，将为机构客户带来更优质更专业的算法服务。
                  </p>
                  <p class="mb-4">
                    <strong
                      >当需要大量买入（或卖出）一只股票时，你是否开始忧虑：</strong
                    >
                  </p>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-exclamation-circle text-primary"></i>
                      会不会拉高建仓成本？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-exclamation-circle text-primary"></i>
                      会不会对市场造成很大冲击？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-exclamation-circle text-primary"></i>
                      会不会引起监管部门的关注？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-exclamation-circle text-primary"></i>
                      会不会暴露交易意图？
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-exclamation-circle text-primary"></i>
                      ......
                    </li>
                  </ul>
                  <p class="mb-4">那么，是时候来了解一下“非凸智能算法”啦！</p>
                  <h4 class="fw-bolder mb-4 mt-5">一、非凸智能算法</h4>
                  <p class="mb-4">
                    非凸智能算法是非凸科技推出的一种主动型算法，致力于服务机构投资者，为其提供以成交为目的的自动化交易执行。
                  </p>
                  <p class="mb-4">
                    该算法能够有效减少冲击成本，尤其对中高换手率产品带来大幅业绩提升，在算法交易执行领域表现领先。
                  </p>
                  <h4 class="fw-bolder mb-4 mt-5">二、如何降低冲击成本</h4>
                  <p class="mb-4">
                    <strong>以10亿规模、100倍换手率的产品为例：</strong>
                  </p>

                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-record text-primary"></i>
                      假设其佣金为万分之2，那么佣金成本仅为：10亿*100*2/10000=2000万
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-record text-primary"></i>
                      人工交易的单位冲击成本为60个基点，那么冲击成本为：10亿*100*60/10000=6亿
                    </li>
                  </ul>
                  <p class="mb-4">
                    <strong
                      >你会惊讶地发现，佣金竟然仅占冲击成本的1/30！</strong
                    >
                  </p>
                  <p class="mb-4">
                    使用非凸智能算法后，单位冲击成本将降为-6个基点，产生冲击成本为：10亿*100*-6/10000=-0.6亿元。
                  </p>
                  <p class="mb-4">
                    也就是说，如果使用非凸智能算法进行交易，在其他客观条件不变的情况下，一年可以节省6.6亿的成本，对应的业绩提升为66%。
                  </p>
                  <h4 class="fw-bolder mb-4 mt-5">
                    三、非凸智能算法适合哪些类型投资者
                  </h4>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-check2-square text-primary"></i>
                      量投基金：多账户调仓
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check2-square text-primary"></i>
                      价值投资基金：多账户调仓
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check2-square text-primary"></i>
                      公募基金：下单执行算法
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check2-square text-primary"></i>
                      创投基金：持股解禁抛售
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check2-square text-primary"></i>
                      上市公司股东或个人高净值投资者：增减持
                    </li>
                  </ul>
                  <h4 class="fw-bolder mb-4 mt-5">四、非凸智能算法的优势</h4>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-star text-primary"></i>
                      实盘绩效约6bps
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-star text-primary"></i>
                      高性能，可同时并发处理2万笔以上母单
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-star text-primary"></i>
                      参数灵活配置，提供定制化服务
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-star text-primary"></i>
                      异常交易实时预警
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-star text-primary"></i>
                      提高交易效率，避免人工误差
                    </li>
                  </ul>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News02",
};
</script>

<style></style>
